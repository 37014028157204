import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import { IEmpresaCadastroCompartilhado } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaCadastroCompartilhado';
import { IDTODadosUsuarioAutenticado } from '@/models/DTO/MeuSistema/Usuarios/IDTODadosUsuarioAutenticado';
import { IDTOUsuarioPermissoesDados } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoesDados';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import storeSistema from '@/store/storeSistema';
import ApiERP from '@/core/conectores/ApiERP';
import { IPreferencia } from '@/models/Entidades/MeuSistema/Preferencias/Preferencia';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDadosLogin } from '@/core/models/IDadosLogin';
import { IRetornoAutenticacao } from '@/core/models/IRetornoAutenticacao';
import { IDTOUsuarioRegistroAtividade } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioRegistroAtividade';
import { IDTOMeusAtalhos } from '@/models/DTO/MeuSistema/IDTOMeusAtalhos';
import { IDTOFiltroUsuarioRegistroAtividade } from '@/models/DTO/MeuSistema/Usuarios/IDTOFiltroUsuarioRegistroAtividade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IRecurso } from '@/models/Entidades/MeuSistema/IRecurso';
import ServicoNotificacao from './ServicoNotificacao';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { ParametrosConsultaTarefaSegundoPlano } from '@/models/ParametrosRequisicao/MeuSistema/ParametrosConsultaTarefaSegundoPlano';
import { ITarefaSegundoPlano } from '@/models/Entidades/MeuSistema/TarefasSegundoPlano/ITarefaSegundoPlano';
import { EStatusTarefaSegundoPlano } from '@/models/Enumeradores/MeuSistema/EStatusTarefaSegundoPlano';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import { IMenu } from '@/models/Entidades/MeuSistema/IMenu';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import { obterItem, salvarItem, removerItem } from '@/core/gerentes/GerenteLocalStorage';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { ERelacaoArquivo } from '@/models/Enumeradores/MeuSistema/ERelacaoArquivo';
import { IArquivo } from '@/models/Entidades/MeuSistema/IArquivo';
import { IControleNumeracao } from '@/models/Entidades/MeuSistema/IControleNumeracao';
import { IVersaoSistema } from '@/models/Entidades/MeuSistema/IVersaoSistema';
import { IControleSerie } from '@/models/Entidades/MeuSistema/IControleSerie';
import GerenteAutenticacao from '@/core/gerentes/GerenteAutenticacao';

/**
 * Serviço do Sistema
 * Centraliza e organiza regras essências para o funcionamento do sistema, exemplo: Empresas,Módulos, Permissões, Licenças , Assinaturas e outros
 */

class ServicoSistema {
    private endPoint = 'sistema';

    private apiERP = new ApiERP();

    public async obterDataAtual(): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/data-atual`);
      return result.data;
    }

    public async login(dadosLogin: IDadosLogin): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiERP.post('autenticacao/login', dadosLogin);
      return result.data;
    }

    public async atualizarToken(): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiERP.post('autenticacao/atualizar-token');
      return result.data;
    }

    public async obterEmpresasDoGrupoToken(): Promise<number[]> {
      const result: any = await this.apiERP.get('autenticacao/empresas-token');
      return result.data;
    }

    public async deslogar(): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiERP.post('autenticacao/logout');
      return result.data;
    }

    public async obterDadosEmpresas(ignorarCache: boolean): Promise<IDTODadosEmpresa[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/dados-empresas/ignorar-cache/${ignorarCache}`);
      return result.data;
    }

    public async verificarTokenGrupoEmpresas(): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/verificar/token/grupo-empresas`);
      return result.data;
    }

    public async obterEmpresasComCadastrosCompartilhados(identificadorRecurso: string): Promise<IEmpresaCadastroCompartilhado[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/empresas-cadastros-compartilhados/${identificadorRecurso}`);
      return result.data;
    }

    public async obterDadosUsuarioAutenticado(): Promise<IDTODadosUsuarioAutenticado> {
      const result: any = await this.apiERP.get(`${this.endPoint}/dados-usuario-autenticado`);
      return result.data;
    }

    public async obterPermissoesDadosUsuario(identificadorPermissao: string): Promise<IDTOUsuarioPermissoesDados[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/permissoes-dados-usuario/${identificadorPermissao}`);
      return result.data;
    }

    public async obterVariasPermissoesDadosUsuario(permissoes: string[]): Promise<IDTOUsuarioPermissoesDados[]> {
      const listaPermissoesDados:IDTOUsuarioPermissoesDados[] = [];
      permissoes.forEach(async (identificadorPermissao) => {
        const listaPermissoesApi = await this.obterPermissoesDadosUsuario(identificadorPermissao);
        if (UtilitarioGeral.validaLista(listaPermissoesApi)) {
          listaPermissoesDados.push(...listaPermissoesApi);
        }
      });
      return listaPermissoesDados;
    }

    public async obterPermissoesAutorizacoesUsuario(identificadorPermissao: string): Promise<IDTOUsuarioPermissoes[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/permissoes-autorizacoes-usuario/${identificadorPermissao}`);
      return result.data;
    }

    public async obterVariasPermissoesAutorizacoesUsuario(autorizacoes: string[]): Promise<IDTOUsuarioPermissoes[]> {
      const listaAutorizacoes:IDTOUsuarioPermissoes[] = [];
      autorizacoes.forEach(async (identificadorAutorizacao) => {
        const listaAutorizacoesApi = await this.obterPermissoesAutorizacoesUsuario(identificadorAutorizacao);
        if (UtilitarioGeral.validaLista(listaAutorizacoesApi)) {
          listaAutorizacoes.push(...listaAutorizacoesApi);
        }
      });
      return listaAutorizacoes;
    }

    public async validarPermissaoModulo(empresa: number, modulo: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/permissao-modulo/empresa/${empresa}/modulo/${modulo}`);
      return result.data;
    }

    public async obterPermissoesWidgetsUsuario(identificadorPermissao: string): Promise<IDTOUsuarioPermissoes[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/permissoes-widgets-usuario/${identificadorPermissao}`);
      return result.data;
    }

    public async obterPermissoesRelatoriosUsuario(identificadorPermissao: string): Promise<IDTOUsuarioPermissoes[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/permissoes-relatorios-usuario/${identificadorPermissao}`);
      return result.data;
    }

    public async salvarPreferenciaUsuario(objeto: IPreferencia): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/preferencias/usuario`, objeto);
      return result.data;
    }

    public async redefinirPreferenciaUsuario(objeto: IPreferencia): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/preferencias/usuario/redefinir`, objeto);
      return result.data;
    }

    public async obterPreferenciaRecurso(identificadorRecurso: string, empresas: Array<number>): Promise<IPreferencia[]> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(empresas);
      const result: any = await this.apiERP.get(`${this.endPoint}/preferencias/recurso/${identificadorRecurso}${parametrosEmpresas}`);
      return result.data;
    }

    public async obterRecursos(valorPequisa: string, empresas: Array<number>, apenasMovimentoEstoque?: boolean): Promise<IRecurso[]> {
      let parametrosAdicionais = this.apiERP.obterParametroEmpresas(empresas);
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `valorPequisa=${valorPequisa}`;
      if (apenasMovimentoEstoque !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `apenasMovimentoEstoque=${apenasMovimentoEstoque}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/recursos${parametrosAdicionais}`);
      return result.data;
    }

    public async obterTodosRecursos(): Promise<IRecurso[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/recursos/todos`);
      return result.data;
    }

    public async obterRegistroAtividadeRecurso(identificadorRecurso: string, empresas: Array<number>): Promise<IDTOUsuarioRegistroAtividade[]> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(empresas);
      const result: any = await this.apiERP.get(`${this.endPoint}/registro-atividades/recurso/${identificadorRecurso}${parametrosEmpresas}`);
      return result.data;
    }

    public async obterRegistroAtividade(filtroUsuarioRegistroAtividade: IDTOFiltroUsuarioRegistroAtividade): Promise<IDTOUsuarioRegistroAtividade[]> {
      let parametrosAdicionais = this.apiERP.obterParametroEmpresas(filtroUsuarioRegistroAtividade.empresas);

      if (filtroUsuarioRegistroAtividade.recursos !== undefined) {
        filtroUsuarioRegistroAtividade.recursos.forEach((item) => {
          parametrosAdicionais += `&Recurso=${item}`;
        });
      }

      if (UtilitarioGeral.validaLista(filtroUsuarioRegistroAtividade.usuarios)) {
        filtroUsuarioRegistroAtividade.usuarios.forEach((codigoUsuario) => {
          parametrosAdicionais += `&Usuarios=${codigoUsuario}`;
        });
      }
      if (UtilitarioGeral.valorValido(filtroUsuarioRegistroAtividade.detalhe)) {
        parametrosAdicionais += `&Detalhe=${filtroUsuarioRegistroAtividade.detalhe}`;
      }

      if (UtilitarioGeral.valorValido(filtroUsuarioRegistroAtividade.dataInicial)) {
        parametrosAdicionais += `&DataInicial=${filtroUsuarioRegistroAtividade.dataInicial}`;
      }

      if (UtilitarioGeral.valorValido(filtroUsuarioRegistroAtividade.dataFinal)) {
        parametrosAdicionais += `&DataFinal=${filtroUsuarioRegistroAtividade.dataFinal}`;
      }

      if (filtroUsuarioRegistroAtividade.ordenacao !== undefined) {
        filtroUsuarioRegistroAtividade.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/registro-atividades${parametrosAdicionais}`);
      return result.data;
    }

    public async adicionarAtalho(identificadorRecurso: string, empresas: Array<number>): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/adicionar-atalho/${identificadorRecurso}`, empresas);
      return result.data;
    }

    public async removerAtalho(identificadorRecurso: string, empresas: Array<number>): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/remover-atalho/${identificadorRecurso}`, empresas);
      return result.data;
    }

    public async obterMeusAtalhos(): Promise<IDTOMeusAtalhos[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/meus-atalhos`);
      return result.data;
    }

    public async obterMenu(empresa:number): Promise<IMenu[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/menu/empresa/${empresa}`);
      return result.data;
    }

    public async obterRecursosCadastroCompartilhado(): Promise<IRecurso[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/recursos-cadastro-compartilhado`);
      return result.data;
    }

    public async salvamentoAutomaticoApi(objeto: ISalvamentoAutomatico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/salvamento-automatico`, objeto);
      return result.data;
    }

    public async obterUltimoSalvamentoAutomaticoApi(codigoEmpresa: number, codigoUsuario:number, identificador: string): Promise<ISalvamentoAutomatico> {
      const result: any = await this.apiERP.get(`${this.endPoint}/salvamento-automatico/empresa/${codigoEmpresa}/usuario/${codigoUsuario}/identificador/${identificador}`);
      return result.data;
    }

    public async removerSalvamentoAutomaticoApi(codigoEmpresa: number, codigoUsuario:number, identificador: string): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/salvamento-automatico/empresa/${codigoEmpresa}/usuario/${codigoUsuario}/identificador/${identificador}`);
      return result.data;
    }

    public async salvamentoAutomaticoLocalStorage(identificador:string, objeto: ISalvamentoAutomatico): Promise<void> {
      salvarItem(identificador, JSON.stringify(objeto));
    }

    public async obterSalvamentoAutomaticoLocalStorage(identificador: string): Promise<ISalvamentoAutomatico> {
      const salvamentoAutomaticoLocalStorage = obterItem(identificador) as ISalvamentoAutomatico;

      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        return salvamentoAutomaticoLocalStorage;
      }
      return {} as ISalvamentoAutomatico;
    }

    public obterLocalStorage(chave: string): string {
      const valor = obterItem(chave);

      if (UtilitarioGeral.valorValido(String(valor))) {
        return String(valor);
      }
      return '';
    }

    public salvarLocalStorage(chave:string, valor: string): void {
      salvarItem(chave, valor);
    }

    public obterSalvamentoMaisRecente(salvamentoAutomaticoApi: ISalvamentoAutomatico, salvamentoAutomaticoLocalStorage: ISalvamentoAutomatico): ISalvamentoAutomatico {
      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoApi) && UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        if (UtilitarioData.validaDataPreenchida(salvamentoAutomaticoApi.data) && UtilitarioData.validaDataPreenchida(salvamentoAutomaticoLocalStorage.data)) {
          if (salvamentoAutomaticoApi.data === salvamentoAutomaticoLocalStorage.data) {
            return salvamentoAutomaticoApi;
          }
          if (UtilitarioData.verificaDataMaisRecente(salvamentoAutomaticoApi.data, salvamentoAutomaticoLocalStorage.data)) {
            return salvamentoAutomaticoApi;
          } if (UtilitarioData.verificaDataMaisRecente(salvamentoAutomaticoLocalStorage.data, salvamentoAutomaticoApi.data)) {
            return salvamentoAutomaticoLocalStorage;
          }
        }
      }

      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoApi) && !UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        return salvamentoAutomaticoApi;
      }

      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage) && !UtilitarioGeral.objetoValido(salvamentoAutomaticoApi)) {
        return salvamentoAutomaticoLocalStorage;
      }

      return {} as ISalvamentoAutomatico;
    }

    public removerSalvamentoAutomaticoLocalStorage(identificador: string): void {
      removerItem(identificador);
    }

    /**
   * Verifica se a empresa em operação compartilha cadastro no recurso que está sendo utilizado
   * @param identificadorRecurso - Identificador do recurso
   * @returns boolean - Se compartilha cadastro ou não
   */
    public async empresaEmOperacaoCompartilhaCadastro(identificadorRecurso: string):Promise<boolean> {
      if (storeSistema.state.multiEmpresas) {
        const cadastrosCompartilhados = await this.obterEmpresasComCadastrosCompartilhados(identificadorRecurso);
        const definicoesEncontradas = cadastrosCompartilhados.filter((c) => c.codigoEmpresa === storeSistema.state.empresaEmOperacao.codigoEmpresa && c.compartilhar === true);
        if (definicoesEncontradas !== undefined) {
          return definicoesEncontradas[0].compartilhar;
        }
      }
      return false;
    }

    /**
   * Obtêm lista de empresas baseando-se nas permissões de autorizações,widgets e relatórios
   * @param identificadorPermissao - Identificador da permissão
   * @param permissoes - Lista com as permissões do usuário (*Obrigatório)
   * @returns Lista com dados resumidos das Empresas
   */
    public async obterEmpresasComEstrategiaPermissao(identificadorPermissao: string,
      permissoes: IDTOUsuarioPermissoes[]):Promise<IDTODadosEmpresa[]> {
      let empresasDisponiveis: IDTODadosEmpresa[];
      empresasDisponiveis = [];

      if (permissoes !== undefined && permissoes.length > 0) {
        const permissoesEncontradas = permissoes.filter((c) => c.identificadorPermissao === identificadorPermissao);
        permissoesEncontradas.forEach((permissaoIdentificada) => {
          const dadosEmpresa = storeSistema.state.empresasDisponiveis.find((dados) => dados.codigoEmpresa === permissaoIdentificada.codigoEmpresa);
          if (dadosEmpresa !== undefined) {
            empresasDisponiveis.push(dadosEmpresa);
          }
        });
      } else {
        empresasDisponiveis = [];
        empresasDisponiveis.push(storeSistema.state.empresaEmOperacao);
      }

      return empresasDisponiveis;
    }

    /**
   * Obtêm lista de empresas verificando as permissões de dados do usuário
   * e se utiliza Multi empresas e cadastro compartilhado
   * @param identificadorRecurso - Identificador do recurso
   * @param tipoPermissaoDados - Tipo de permissão dos dados(EPermissaoDados)
   * @param identificadorPermissao - Identificador da permissão
   * @param permissoesDados - Lista com as permissões do usuário (*Obrigatório)
   * @param verificarCadastroCompartilhado - Efetua verificação avançada Multi empresas/Cadastro compartilhado
   * @returns Lista com dados resumidos das Empresas
   */
    public async obterEmpresasComEstrategiaPermissaoDados(identificadorRecurso: string,
      tipoPermissaoDados: EPermissaoDados, identificadorPermissao: string,
      permissoesDados: IDTOUsuarioPermissoesDados[], verificarCadastroCompartilhado: boolean):Promise<IDTODadosEmpresa[]> {
      const empresasDisponiveis: IDTODadosEmpresa[] = [];

      if (permissoesDados !== undefined && permissoesDados.length > 0) {
        const permissoesEncontradas = permissoesDados.filter((c) => c.identificadorPermissao === identificadorPermissao);
        if (permissoesEncontradas.length > 0) {
          permissoesEncontradas.forEach((permissaoDados) => {
            let possuiPermissao: boolean;
            switch (tipoPermissaoDados) {
              case EPermissaoDados.Visualizar:
                possuiPermissao = permissaoDados.visualizar;
                break;
              case EPermissaoDados.Incluir:
                possuiPermissao = permissaoDados.incluir;
                break;
              case EPermissaoDados.Alterar:
                possuiPermissao = permissaoDados.alterar;
                break;
              case EPermissaoDados.Excluir:
                possuiPermissao = permissaoDados.excluir;
                break;
              case EPermissaoDados.Imprimir:
                possuiPermissao = permissaoDados.imprimir;
                break;
              default:
                possuiPermissao = false;
                break;
            }

            if (possuiPermissao) {
              const dadosEmpresa = storeSistema.state.empresasDisponiveis.find((dados) => dados.codigoEmpresa === permissaoDados.codigoEmpresa);
              if (dadosEmpresa !== undefined) {
                empresasDisponiveis.push(dadosEmpresa);
              }
            }
          });
        }
      }
      if (storeSistema.state.multiEmpresas) {
        if (verificarCadastroCompartilhado && empresasDisponiveis.length > 0) {
          const cadastrosCompartilhados = await this.obterEmpresasComCadastrosCompartilhados(identificadorRecurso);
          if (cadastrosCompartilhados.length > 0) {
            cadastrosCompartilhados.forEach((definicaoEmpresa) => {
              if (definicaoEmpresa.compartilhar) {
                const dadosEmpresa = storeSistema.state.empresasDisponiveis.find((dados) => dados.codigoEmpresa === definicaoEmpresa.codigoEmpresa);
                if (dadosEmpresa !== undefined) {
                  if (!empresasDisponiveis.some((c) => c.codigoEmpresa === dadosEmpresa.codigoEmpresa)) {
                    empresasDisponiveis.push(dadosEmpresa);
                  }
                }
              }
            });
          }
        }
      }
      if (empresasDisponiveis.length <= 0) {
        empresasDisponiveis.push(storeSistema.state.empresaEmOperacao);
      }

      return empresasDisponiveis;
    }

    /**
   * Filtra se o usuario possui permissão na empresa informada
   * @param identificadorPermissao - Identificador da permissão
   * @param permissoesDados - Lista com as permissões do usuário (* Obrigatório vir preenchida)
   * @param codigoEmpresa - Código da empresa para efetuar o filtro)
   * @returns usuarioPermissaoDados - Permissões de dados do usuário
   */
    public filtrarPermissaoDadosUsuario(identificadorPermissao: string, permissoesDados: IDTOUsuarioPermissoesDados[], codigoEmpresa:number):IDTOUsuarioPermissoesDados {
      const usuarioPermissaoDados: IDTOUsuarioPermissoesDados = {
        codigoEmpresa, identificadorPermissao, visualizar: false, incluir: false, alterar: false, excluir: false, imprimir: false,
      };

      if (permissoesDados !== undefined && permissoesDados.length > 0) {
        usuarioPermissaoDados.codigoEmpresa = codigoEmpresa === 0 ? storeSistema.state.empresaEmOperacao.codigoEmpresa : codigoEmpresa;
        const permissoesEncontradas = permissoesDados.filter((c) => c.identificadorPermissao === identificadorPermissao && c.codigoEmpresa === usuarioPermissaoDados.codigoEmpresa);
        if (permissoesEncontradas.length > 0) {
          usuarioPermissaoDados.visualizar = permissoesEncontradas[0].visualizar;
          usuarioPermissaoDados.incluir = permissoesEncontradas[0].incluir;
          usuarioPermissaoDados.alterar = permissoesEncontradas[0].alterar;
          usuarioPermissaoDados.excluir = permissoesEncontradas[0].excluir;
          usuarioPermissaoDados.imprimir = permissoesEncontradas[0].imprimir;
        }
      }
      return usuarioPermissaoDados;
    }

    /**
   * Verifica se o usuario possui permissão nas empresas informadas
   * @param tipoPermissaoDados - Tipo de permissão dos dados(EPermissaoDados)
   * @param identificadorPermissao - Identificador da permissão
   * @param permissoesDados - Lista com as permissões do usuário (Preenchimento Obrigatório)
   * @param empresas - Lista com o código das empresas para filtrar (Preenchimento Obrigatório)
   * @returns usuarioPermissaoDados - Permissões de dados do usuário
   */
    public async filtrarPermissaoDadosUsuarioMultiEmpresas(identificadorPermissao: string,
      permissoesDados: IDTOUsuarioPermissoesDados[], empresas: Array<number>):Promise<IDTOUsuarioPermissoesDados> {
      const usuarioPermissaoDados: IDTOUsuarioPermissoesDados = {
        codigoEmpresa: 0, identificadorPermissao, visualizar: false, incluir: false, alterar: false, excluir: false, imprimir: false,
      };
      if (permissoesDados !== undefined && permissoesDados.length > 0) {
        if (empresas.length === 1) {
          const permissaoEncontrada = permissoesDados.find((c) => c.identificadorPermissao === identificadorPermissao && c.codigoEmpresa === empresas[0]);
          if (permissaoEncontrada !== undefined) {
            return permissaoEncontrada;
          }
        } else {
          const permissoesEncontradas = permissoesDados.filter((c) => c.identificadorPermissao === identificadorPermissao && empresas.includes(c.codigoEmpresa));
          if (permissoesEncontradas.length > 0) {
            const permissaoVisualizar = permissoesEncontradas.filter((p) => p.visualizar === false);
            usuarioPermissaoDados.visualizar = !(permissaoVisualizar.length > 0);

            const permissaoIncluir = permissoesEncontradas.filter((p) => p.incluir === false);
            usuarioPermissaoDados.incluir = !(permissaoIncluir.length > 0);

            const permissaoAlterar = permissoesEncontradas.filter((p) => p.alterar === false);
            usuarioPermissaoDados.alterar = !(permissaoAlterar.length > 0);

            const permissaoExcluir = permissoesEncontradas.filter((p) => p.excluir === false);
            usuarioPermissaoDados.excluir = !(permissaoExcluir.length > 0);

            const permissaoImprimir = permissoesEncontradas.filter((p) => p.imprimir === false);
            usuarioPermissaoDados.imprimir = !(permissaoImprimir.length > 0);
          }
        }
      }
      return usuarioPermissaoDados;
    }

    public async preencherDadosSistema(ignorarCache: boolean):Promise<void> {
      const atualizarToken = await this.verificarTokenGrupoEmpresas();
      if (atualizarToken) {
        const retornoAutenticacao = await this.atualizarToken();
        if (retornoAutenticacao.autenticado) {
          new GerenteAutenticacao().salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
        }
      }
      const empresasDisponiveis = await this.obterDadosEmpresas(ignorarCache);
      await storeSistema.mutations.atualizarDadosEmpresas(empresasDisponiveis);
      const dadosUsuarioAutenticado = await this.obterDadosUsuarioAutenticado();
      storeSistema.mutations.atualizarDadosUsuarioAutenticado(dadosUsuarioAutenticado);
      const meusAtalhos = await this.obterMeusAtalhos();
      storeSistema.mutations.atualizarMeusAtalhos(meusAtalhos);
      const menus = await this.obterMenu(storeSistema.getters.codigoEmpresaOperacao());
      storeSistema.mutations.atualizarMenus(menus);
      const quantidadeNotificacoesNaoLidas = await new ServicoNotificacao().obterQuantidadeNotificacoesUsuarioNaoLidas();
      storeSistema.mutations.atualizarNotificacaoNaoLida(quantidadeNotificacoesNaoLidas);
    }

    public async obterListaTipoItem(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-item`);
      return result.data;
    }

    public async obterListaTipoMovimentosComerciais(apenasMovimentosEntradas?:boolean, apenasMovimentosSaidas?:boolean, apenasFaturamentos?:boolean): Promise<IItemGenerico[]> {
      let parametrosAdicionais = '';

      if (apenasFaturamentos !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `apenasFaturamentos=${apenasFaturamentos}`;
      }

      if (apenasMovimentosEntradas !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `apenasMovimentosEntradas=${apenasMovimentosEntradas}`;
      }
      if (apenasMovimentosSaidas !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `apenasMovimentosSaidas=${apenasMovimentosSaidas}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-movimentos-comerciais${parametrosAdicionais}`);
      return result.data;
    }

    public async obterListaStatusVendas(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/status-vendas`);
      return result.data;
    }

    public verificaAutorizacao(empresa: number, identificadorAutorizacao: string, autorizacoes:IDTOUsuarioPermissoes[]): boolean {
      if (UtilitarioGeral.validaLista(autorizacoes)) {
        const autorizacaoEncontrada = autorizacoes.find((c) => c.codigoEmpresa === empresa && c.identificadorPermissao === identificadorAutorizacao);
        if (UtilitarioGeral.objetoValido(autorizacaoEncontrada)) {
          return true;
        }
      }
      return false;
    }

    public verificaMovimentosComerciaisEntrada(tiposMovimentos:number[]): boolean {
      if (UtilitarioGeral.validaLista(tiposMovimentos)) {
        if (tiposMovimentos.some((c) => c === ETipoMovimentoComercial.Compras)
        && tiposMovimentos.some((c) => c === ETipoMovimentoComercial.DevolucaoVendas)) {
          return true;
        }
      }
      return false;
    }

    public verificaMovimentosComerciaisSaida(tiposMovimentos:number[]): boolean {
      if (UtilitarioGeral.validaLista(tiposMovimentos)) {
        if (tiposMovimentos.some((c) => c === ETipoMovimentoComercial.Vendas)
        && tiposMovimentos.some((c) => c === ETipoMovimentoComercial.DevolucaoCompras)
        && tiposMovimentos.some((c) => c === ETipoMovimentoComercial.Servico)) {
          return true;
        }
      }
      return false;
    }

    public async obterTarefaSegundoPlanoRecurso(identificadorRecurso:string, parametrosConsulta?: ParametrosConsultaTarefaSegundoPlano): Promise<ITarefaSegundoPlano> {
      let parametrosAdicionais = '';

      if (parametrosConsulta !== undefined) {
        parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
        if (parametrosConsulta.usuarios !== undefined) {
          parametrosConsulta.usuarios.forEach((codigoUsuario) => {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `usuarios=${codigoUsuario}`;
          });
        }

        if (parametrosConsulta.data !== undefined) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `data=${parametrosConsulta.data}`;
        }

        if (parametrosConsulta.ultimaTarefaEm24Horas !== undefined) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `ultimaTarefaEm24Horas=${parametrosConsulta.ultimaTarefaEm24Horas}`;
        }
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/tarefas-segundo-plano/recurso/${identificadorRecurso}${parametrosAdicionais}`);
      return result.data;
    }

    public async verificarTarefaSegundoPlanoPendente(identificadorRecurso:string, parametrosConsulta?: ParametrosConsultaTarefaSegundoPlano) :Promise<boolean> {
      const tarefaSegundoPlano = await this.obterTarefaSegundoPlanoRecurso(identificadorRecurso, parametrosConsulta);

      if (UtilitarioGeral.objetoValido(tarefaSegundoPlano)) {
        if (tarefaSegundoPlano.status === EStatusTarefaSegundoPlano.EmAberto || tarefaSegundoPlano.status === EStatusTarefaSegundoPlano.EmAndamento) {
          return true;
        }
      }
      return false;
    }

    public obtemVariavelPorTexto(textoVariavel:string):string {
      let variavel = '';
      if (UtilitarioGeral.valorValido(textoVariavel)) {
        variavel = textoVariavel.toLocaleUpperCase();
        variavel = variavel.replace(' ', '_');
        variavel = `[{${variavel}}]`;
      }
      return variavel;
    }

    public montaTextoComVariavelConteudo(texto:string, textoVariavel:string):string {
      let retorno = '';
      if (UtilitarioGeral.valorValido(texto)) {
        retorno = texto;
      }

      retorno += this.obtemVariavelPorTexto(textoVariavel);
      return retorno;
    }

    public async gerarLinkArquivoPrivado(pasta:string, nomeArquivo:string): Promise<IRetornoArquivo> {
      const arquivo: IArquivo = {} as IArquivo;
      arquivo.pasta = pasta;
      arquivo.nome = nomeArquivo;
      const result: any = await this.apiERP.post(`${this.endPoint}/arquivos/link-privado`, arquivo);
      return result.data;
    }

    public async enviarArquivoUnicoPublico(empresa: number, relacaoArquivo:ERelacaoArquivo, arquivo: any): Promise<IRetornoArquivo> {
      const data = new FormData();
      data.append('arquivos', arquivo);

      const result: any = await this.apiERP.upload(`${this.endPoint}/arquivos/publicos/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
      return result.data[0];
    }

    public async enviarArquivosPublicos(empresa: number, relacaoArquivo:ERelacaoArquivo, arquivos: any[]): Promise<IRetornoArquivo[]> {
      const data = new FormData();
      for (let i = 0; i < arquivos.length; (i += 1)) {
        data.append('arquivos', arquivos[i]);
      }

      const result: any = await this.apiERP.upload(`${this.endPoint}/arquivos/publicos/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
      return result.data;
    }

    public async enviarArquivoUnicoPrivado(empresa: number, relacaoArquivo:ERelacaoArquivo, arquivo: any): Promise<IRetornoArquivo> {
      const data = new FormData();
      data.append('arquivos', arquivo);

      const result: any = await this.apiERP.upload(`${this.endPoint}/arquivos/privados/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
      return result.data[0];
    }

    public async enviarArquivosPrivados(empresa: number, relacaoArquivo:ERelacaoArquivo, arquivos: any[]): Promise<IRetornoArquivo[]> {
      const data = new FormData();
      for (let i = 0; i < arquivos.length; (i += 1)) {
        data.append('arquivos', arquivos[i]);
      }

      const result: any = await this.apiERP.upload(`${this.endPoint}/arquivos/privados/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
      return result.data;
    }

    public async removerArquivoPorUrl(url: string): Promise<IRetornoArquivo> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/arquivos`, url);
      return result.data;
    }

    public async enviarEmailRedefinicaoSenha(email: string): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post('autenticacao/email-redefinicao-senha', email);
      return result.data;
    }

    public async validarRedefinicaoSenha(codigoAcesso: string): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.get(`autenticacao/validar-redefinicao-senha/${codigoAcesso}`);
      return result.data;
    }

    public async redefinirSenha(codigoAcesso: string, senhaNova: string): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`autenticacao/redefinir-senha/${codigoAcesso}`, senhaNova);
      return result.data;
    }

    public async obterControleNumeracoes(codigoEmpresa:number): Promise<IControleNumeracao[]> {
      const result: any = await this.apiERP.get(`sistema/controle-numeracoes/empresa/${codigoEmpresa}`);
      return result.data;
    }

    public async atualizarControleNumeracao(empresa: number, controlesNumeracao:IControleNumeracao[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`sistema/controle-numeracoes/empresa/${empresa}`, controlesNumeracao);
      return result.data;
    }

    public async obterControleSeries(codigoEmpresa:number): Promise<IControleSerie[]> {
      const result: any = await this.apiERP.get(`sistema/controle-series/empresa/${codigoEmpresa}`);
      return result.data;
    }

    public async atualizarControleSerie(empresa: number, controlesSerie:IControleSerie[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`sistema/controle-series/empresa/${empresa}`, controlesSerie);
      return result.data;
    }

    public async obterVersaoSistema(): Promise<IVersaoSistema> {
      const result: any = await this.apiERP.get(`${this.endPoint}/versao-sistema`);
      return result.data;
    }
}
export default ServicoSistema;
